import {
  isValidEthereumAddress,
  isValidGalaChainAddress,
} from './validateAddress';

const validateWalletAddress = (
  address: string,
  symbol: string = 'ETH',
  validationStrategy?: {
    strategyName: string;
    expression?: string;
  },
): boolean => {
  // This is so QA can test without us having to turn on this strategy in the backend
  // (all clients must be ready before we can do that)
  if ((globalThis as any).forceValidateAsGalaChain) {
    return isValidGalaChainAddress(address);
  }

  if (
    validationStrategy?.strategyName === 'REGEX' &&
    validationStrategy?.expression
  ) {
    if (symbol === 'POPCORN[GYRI]') {
      return address === 'client|film-ops';
    }

    return RegExp(validationStrategy.expression).test(address);
  } else if (validationStrategy?.strategyName === 'GALACHAIN') {
    return isValidGalaChainAddress(address);
  }

  return isValidEthereumAddress(address);
};

export default validateWalletAddress;
