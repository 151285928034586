export type BridgeableNetworks = ChainNetwork.ETHEREUM | ChainNetwork.GYRI;

export enum ChainNetwork {
  ETHEREUM = 'ETHEREUM',
  GYRI = 'GYRI',
  ETH_TREASURE_CHEST = 'ETH_TREASURE_CHEST',
  GYRI_TREASURE_CHEST = 'GALACHAIN_ALLOWANCE',
  OFF_CHAIN_TOKEN = 'OFF_CHAIN_TOKEN',
}

export enum EthereumTokenStandard {
  ERC1155 = 'erc1155',
  ERC20 = 'erc20',
  ERC721 = 'erc721',
}

export enum EthereumTokenStandardUppercase {
  ERC1155 = 'ERC1155',
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
}

export enum GalaChainMethods {
  MintToken = 'MintToken',
  TransferToken = 'TransferToken',
  BurnTokens = 'BurnTokens',
}

export interface IChainDisplayInfo {
  network: ChainNetwork;
  contractType?: EthereumTokenStandard;
  chainDisplayNameShortKey: string;
  chainDisplayNameWithContractTypeKey: string;
  chainDisplayNameWithContractType: string;
  chainDisplayNameWithoutContractTypeKey: string;
  chainDisplayNameWithoutContractType: string;
  hasSendGasFees?: boolean;
  hasExportGasFees?: boolean;
  hasMintingGasFees?: boolean;
  icon?: string;
  buttonIcon?: string;
  iconAltText?: string;
  textColor?: string;
  isValidAddress: (address: string) => boolean;
}

export interface ITokenClassKey {
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}

export interface ITokenInstanceKey extends ITokenClassKey {
  instance: string;
}

export interface ITokenInsanceKeyWithQuantity {
  tokenInstanceKey: ITokenInstanceKey;
  quantity: string;
}
