import { NuxtI18nInstance } from '@nuxtjs/i18n';
import {
  ChainNetwork,
  EthereumTokenStandard,
  IChainDisplayInfo,
} from '~/types/chain';
import {
  isValidEthereumAddress,
  isValidGalaChainAddress,
  isValidGalaChainClientAddress,
} from '~/utils/validateAddress';

const chains: IChainDisplayInfo[] = [
  {
    network: ChainNetwork.ETH_TREASURE_CHEST,
    contractType: undefined,
    chainDisplayNameShortKey: 'common.transactions.treasureChest',
    chainDisplayNameWithContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithoutContractType: '',
    hasSendGasFees: undefined, // sending not supported
    hasExportGasFees: true,
    isValidAddress: isValidEthereumAddress,
  },
  {
    network: ChainNetwork.GYRI_TREASURE_CHEST,
    contractType: undefined,
    chainDisplayNameShortKey: 'common.transactions.treasureChest',
    chainDisplayNameWithContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithoutContractType: '',
    hasSendGasFees: false, // sending not supported
    hasExportGasFees: undefined,
    hasMintingGasFees: true,
    isValidAddress: isValidGalaChainAddress,
  },
  {
    network: ChainNetwork.ETHEREUM,
    contractType: EthereumTokenStandard.ERC1155,
    chainDisplayNameShortKey: 'common.transactions.ethSymbol',
    chainDisplayNameWithContractTypeKey: 'common.transactions.ethErc1155',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum ERC-1155 Icon',
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
    isValidAddress: isValidEthereumAddress,
  },
  {
    network: ChainNetwork.ETHEREUM,
    contractType: EthereumTokenStandard.ERC20,
    chainDisplayNameShortKey: 'common.transactions.ethSymbol',
    chainDisplayNameWithContractTypeKey: 'common.transactions.ethErc20',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum ERC-20 Icon',
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
    isValidAddress: isValidEthereumAddress,
  },
  {
    network: ChainNetwork.ETHEREUM,
    contractType: EthereumTokenStandard.ERC721,
    chainDisplayNameShortKey: 'common.transactions.ethSymbol',
    chainDisplayNameWithContractTypeKey: 'common.transactions.ethErc721',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum ERC-721 Icon',
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
    isValidAddress: isValidEthereumAddress,
  },
  {
    network: ChainNetwork.ETHEREUM,
    contractType: undefined,
    chainDisplayNameShortKey: 'common.transactions.ethSymbol',
    chainDisplayNameWithContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum Icon',
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
    isValidAddress: isValidEthereumAddress,
  },
  {
    network: ChainNetwork.GYRI,
    contractType: undefined,
    chainDisplayNameShortKey: 'common.transactions.gyri',
    chainDisplayNameWithContractTypeKey: 'common.transactions.gyri',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.gyri',
    chainDisplayNameWithoutContractType: '',
    icon: require('@/assets/icons/icon_gyri_inventory.png'),
    buttonIcon: require('@/assets/icons/icon_gyri_button.png'),
    iconAltText: 'GYRI Icon',
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
    isValidAddress: isValidGalaChainAddress,
  },
  {
    network: ChainNetwork.OFF_CHAIN_TOKEN,
    contractType: undefined,
    chainDisplayNameShortKey: 'common.transactions.gyri',
    chainDisplayNameWithContractTypeKey: 'common.transactions.gyri',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.gyri',
    chainDisplayNameWithoutContractType: '',
    icon: require('@/assets/icons/icon_gyri_inventory.png'),
    buttonIcon: require('@/assets/icons/icon_gyri_button.png'),
    iconAltText: 'GYRI Icon',
    hasSendGasFees: false,
    hasExportGasFees: undefined, // exporting not supported yet
    isValidAddress: isValidGalaChainClientAddress,
  },
];

export function translateChainDisplayInfo(i18n: NuxtI18nInstance) {
  chains.forEach((chain) => {
    chain.chainDisplayNameWithContractType = i18n.t(
      chain.chainDisplayNameWithContractTypeKey,
    ) as string;
    chain.chainDisplayNameWithoutContractType = i18n.t(
      chain.chainDisplayNameWithoutContractTypeKey,
    ) as string;
  });
}

export function getChainInfo(
  network: ChainNetwork | EthereumTokenStandard,
  contractType?: EthereumTokenStandard | null,
) {
  contractType = contractType || undefined;

  return chains.find(
    (chain) => chain.network === network && chain.contractType === contractType,
  );
}
