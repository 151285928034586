import { ethers } from 'ethers';
import WAValidator from 'wallet-address-validator';

export function isValidGalaChainClientAddress(address: string) {
  return /^client\|[a-f0-9]{24}$/.test(address);
}

export function isValidGalaChainAddress(address: string) {
  if (address === 'client|film-ops') {
    return true;
  } else if (address.startsWith('client|')) {
    return isValidGalaChainClientAddress(address);
  } else if (address.startsWith('eth|')) {
    const addressWithoutEthPrefix = address.replace('eth|', '');
    if (addressWithoutEthPrefix.startsWith('0x')) {
      return false;
    }

    const addressPartWithEthPrefix = `0x${addressWithoutEthPrefix}`;

    try {
      const addressPartWithEthPrefixCheckSummed = ethers.utils.getAddress(
        addressPartWithEthPrefix,
      );
      return addressPartWithEthPrefix === addressPartWithEthPrefixCheckSummed;
    } catch (err) {
      console.warn(err);
      return false;
    }
  } else {
    return false;
  }
}

export function isValidEthereumAddress(address: string) {
  return WAValidator.validate(
    address,
    'ETH',
    process.env.networkType || 'both',
  );
}
