import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State, Getter } from 'vuex-class';
import {
  BigNumber,
  BytesLike,
  constants,
  Contract,
  ContractInterface,
  providers,
  utils,
} from 'ethers';
import { ExternalProvider, TransactionRequest } from '@ethersproject/providers';
import {
  IConnectedWallet,
  IExternalWallet,
  Network,
  IProviderOption,
  IWalletConnectionError,
  WalletConnectionErrorCode,
} from '~/store/web3_wallet/types';
import { IProfileState } from '~/store/profile/types';
import { itemContractConfigs } from '~/data/itemContractConfigs';
import erc20Abi from '~/data/abi/erc20.json';
import erc1155Abi from '~/data/abi/erc1155.json';
import erc721Abi from '~/data/abi/erc721.json';
import galaAbi from '~/data/abi/gala.json';
import savePendingW3wTransactionMutation from '~/mutations/savePendingW3wTransaction.gql';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import {
  EthereumTokenStandard,
  EthereumTokenStandardUppercase,
} from '~/types/chain';
import { ICreateWalletPrompt, WalletType } from '~/store/types';
import { wait } from '~/utils';

@Component
export default class ExternalWallet extends Vue {
  @State((web3Wallet) => web3Wallet.connectedExternalWallet.ethAddress, {
    namespace: 'web3Wallet',
  })
  readonly externalWalletAddress!: string;
  @State((profile: IProfileState) => profile.user.walletConnected, {
    namespace: 'profile',
  })
  readonly walletConnected!: boolean;
  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State((w3w) => w3w.providerOptions, { namespace: 'web3Wallet' })
  providerOptions!: IProviderOption[];
  @State((w3w) => w3w.connectedWallet, { namespace: 'web3Wallet' })
  connectedWallet!: IConnectedWallet;
  @State((w3w) => w3w.connectedExternalWallet, { namespace: 'web3Wallet' })
  connectedExternalWallet!: IExternalWallet;
  @State((w3w) => w3w.provider, { namespace: 'web3Wallet' })
  provider!: ExternalProvider;
  @State((w3w) => w3w.web3Provider, { namespace: 'web3Wallet' })
  web3Provider!: providers.Web3Provider;
  @State((w3w) => w3w.expectedNetwork, { namespace: 'web3Wallet' })
  expectedNetwork!: Network;
  @State((w3w) => w3w.walletConnectionError, { namespace: 'web3Wallet' })
  walletConnectionError!: IWalletConnectionError | null;

  @Getter('w3wConnectionActive', { namespace: 'web3Wallet' })
  readonly w3wConnectionActive!: boolean;

  @Action('connectWalletToApp', { namespace: 'web3Wallet' })
  private connectWalletToApp!: (args: {
    label: string;
    connectToUser?: boolean;
    twoFaWrapper: <TReturnType>(
      queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
      twoFaCheckpoint?: TwoFaCheckpoint,
    ) => Promise<TReturnType>;
  }) => {
    success: boolean;
    message?: string;
    cause?: WalletConnectionErrorCode;
  };

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;
  @Mutation toggleSuccessSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarSuccessText!: (args: any) => void;
  @Mutation('toggleCreateWalletPrompt')
  toggleCreateWalletPrompt!: (payload?: Partial<ICreateWalletPrompt>) => void;
  @Action('reestablishW3wConnection', { namespace: 'web3Wallet' })
  private reestablishW3wConnection!: () => void;

  w3wConnectionEnabled = process.env.w3wConnectionEnabled;
  walletConnectionErrorCode = WalletConnectionErrorCode;
  walletTypes = WalletType;

  getContractAbi(contractType: EthereumTokenStandardUppercase) {
    switch (contractType) {
      case EthereumTokenStandardUppercase.ERC20:
        return erc20Abi;
      case EthereumTokenStandardUppercase.ERC721:
        return erc721Abi;
      case EthereumTokenStandardUppercase.ERC1155:
        return erc1155Abi;
    }
  }

  async establishWalletConnection() {
    try {
      await this.reestablishW3wConnection();
      return true;
    } catch (e) {
      return false;
    }
  }

  integerizeAmount(amountDecimalString: string, decimals: number) {
    return utils.parseUnits(amountDecimalString, decimals);
  }

  getContractInstance(
    contractAddress: string,
    contractInterface: ContractInterface,
  ) {
    if (this.w3wConnectionActive) {
      return new Contract(
        contractAddress,
        contractInterface,
        this.web3Provider!.getSigner(this.externalWalletAddress),
      );
    } else {
      throw new Error(
        (this.walletConnectionError && this.walletConnectionError.message) ||
          (this.$t(
            'components.wallet.connectWeb3Wallet.errorMessages.missingProvider',
          ) as string),
      );
    }
  }

  buildIface(abi: any) {
    return new utils.Interface(abi);
  }

  async connect(
    params: { label: string; connectToUser?: boolean },
    twoFaWrapper: <TReturnType>(
      queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
      twoFaCheckpoint?: TwoFaCheckpoint,
    ) => Promise<TReturnType>,
  ) {
    const response = await this.connectWalletToApp({ ...params, twoFaWrapper });
    return response;
  }

  async getSigner() {
    if (!this.provider || !this.externalWalletAddress) {
      this.toggleCreateWalletPrompt({
        show: true,
        walletType: this.walletTypes.ETH,
      });
      return false;
    }

    if (!this.w3wConnectionActive) {
      const message =
        (this.walletConnectionError && this.walletConnectionError.message) ||
        this.$t(
          'components.home.walletConnectionErrorBanner.missingBrowserConnectionShort',
        );
      this.updateSnackbarErrorText(message);
      this.toggleErrorSnackbar();
      return false;
    }
    const signer = this.web3Provider.getSigner(this.externalWalletAddress);
    return signer;
  }

  async signTransaction({
    amount,
    value,
    destinationAddress,
    transactionFeePrice,
    data,
    gasEstimate = 0,
    gasLimit,
    gasPrice,
  }: {
    destinationAddress: string;
    transactionFeePrice: number;
    amount?: string;
    value?: BigNumber;
    data?: BytesLike;
    gasEstimate?: number;
    gasLimit?: BigNumber;
    gasPrice?: BigNumber;
  }) {
    const signer = await this.getSigner();
    if (signer) {
      const params: TransactionRequest = {
        chainId: this.expectedNetwork,
        from: this.externalWalletAddress,
        gasLimit: gasLimit || BigNumber.from(Math.max(54000, gasEstimate)),
        gasPrice: gasPrice || BigNumber.from(transactionFeePrice),
        to: destinationAddress,
      };
      if (amount || value) {
        params.value = value || BigNumber.from(amount);
      }
      if (data) {
        params.data = data;
      }
      return signer.sendTransaction(params);
    }
  }

  async sendCurrency({
    destinationAddress,
    transactionFeePrice,
    amount,
    contractAddress,
    sendMax,
    symbol,
  }: {
    destinationAddress: string;
    transactionFeePrice: number;
    amount: string;
    symbol: string;
    contractAddress?: string;
    sendMax?: boolean;
  }) {
    const gasPrice = BigNumber.from(transactionFeePrice);
    let tx = null;

    if (contractAddress) {
      const contract = this.getContractInstance(contractAddress, erc20Abi);
      const decimals = await contract.decimals();
      if (decimals === 0 && !Number.isInteger(+amount)) {
        throw new Error(`Sending ${symbol} requires a whole number`);
      }

      const gasFallbackAmount = process.env.gasFallbackAmount
        ? Number(process.env.gasFallbackAmount)
        : 55000;

      const formattedValue = utils.parseUnits(amount, decimals);
      const gasLimit = await this.getGasEstimate(
        contract,
        'transfer',
        [destinationAddress, formattedValue, { gasPrice }],
        gasFallbackAmount,
      );

      tx = await contract.transfer(destinationAddress, formattedValue, {
        gasPrice,
        gasLimit,
      });
    } else {
      const gasLimitNumber = process.env.gasMaximumLimit
        ? Number(process.env.gasMaximumLimit)
        : 21000;
      const gasLimit = BigNumber.from(gasLimitNumber);

      if (sendMax) {
        amount = utils.formatEther(
          (
            await this.web3Provider.getBalance(this.connectedWallet.address)
          ).sub(gasLimit.mul(gasPrice)),
        );
      }
      tx = await this.signTransaction({
        destinationAddress,
        transactionFeePrice,
        value: utils.parseEther(amount),
        gasLimit,
        gasPrice,
      });
    }

    if (tx) {
      await this.$apollo.mutate({
        mutation: savePendingW3wTransactionMutation,
        variables: { hash: tx.hash, symbol },
      });
      return { success: true };
    }
    return { success: false, message: 'Transaction Failed' };
  }

  async sendGameItem({
    quantity,
    contractAddress,
    destinationAddress,
    tokenId,
    transactionFeePrice,
  }: {
    quantity: number;
    contractAddress: string;
    destinationAddress: string;
    tokenId: string;
    transactionFeePrice: number;
  }) {
    const contractConfig = itemContractConfigs.find(
      (config) => config.contractAddress === contractAddress,
    );
    if (!contractConfig) {
      throw new Error('Token contract not found');
    }
    const contract = this.getContractInstance(
      contractAddress,
      contractConfig.contractInterface,
    );

    let tx = null;

    if (contractConfig.contractType === EthereumTokenStandard.ERC1155) {
      tx = await contract.safeTransferFrom(
        this.connectedWallet.address,
        destinationAddress,
        tokenId,
        quantity,
        [],
        { gasPrice: transactionFeePrice },
      );
    } else {
      tx = await contract.transferFrom();
    }

    if (tx) {
      // TODO: Do we need to hit app-server to create notification, or should we just let the indexer handle that?
      return { success: true };
    }

    return { success: false, message: 'Transaction Failed' };
  }

  checkW3wConnectionEnabled() {
    if (this.w3wConnectionEnabled) {
      return true;
    }

    const errorMsg = this.$t(
      'components.wallet.connectWeb3Wallet.errorMessages.w3wDisabled',
    );

    this.updateSnackbarErrorText(errorMsg);
    this.toggleErrorSnackbar();

    return false;
  }

  async getHasGrantedAllowance(
    contractAddress: string,
    contractType: EthereumTokenStandardUppercase,
    addressToApprove: string,
    amount?: number,
  ) {
    const contract = this.getContractInstance(
      contractAddress,
      this.getContractAbi(contractType),
    );

    switch (contractType) {
      case EthereumTokenStandardUppercase.ERC20:
        const [decimals, remainingAllowance] = await Promise.all([
          contract.decimals(),
          contract.allowance(this.connectedWallet.address, addressToApprove),
        ]);

        return remainingAllowance.gte(
          BigNumber.from(amount).mul(10 ** decimals),
        );
      case EthereumTokenStandardUppercase.ERC721:
      case EthereumTokenStandardUppercase.ERC1155:
        const isApprovedForAll = await contract.isApprovedForAll(
          this.connectedWallet.address,
          addressToApprove,
        );

        return isApprovedForAll;
    }
  }

  async getGasEstimate(
    contract: Contract,
    contractMethod: string,
    contractInputs: any[],
    fallbackValue: number,
  ) {
    const fallback = BigNumber.from(fallbackValue);
    const waitTime = process.env.gasEstimateWaitMs
      ? Number(process.env.gasEstimateWaitMs)
      : 500;

    try {
      const estimatedValue = await Promise.race([
        contract.estimateGas[contractMethod](...contractInputs),
        (async () => {
          await wait(waitTime);

          return fallback;
        })(),
      ]);

      return estimatedValue.gt(fallback) ? estimatedValue : fallback;
    } catch {
      return fallback;
    }
  }

  async getPermitSignature(
    contractAddress: string,
    addressToApprove: string,
    value: BigNumber,
  ) {
    const contract = this.getContractInstance(contractAddress, galaAbi);

    const signer = await this.getSigner();

    if (signer) {
      const [nonce, name, chainId] = await Promise.all([
        contract.nonces(signer._address),
        contract.name(),
        signer.getChainId(),
      ]);

      const deadline = constants.MaxUint256;

      const signature = await signer._signTypedData(
        {
          name,
          version: '1',
          chainId,
          verifyingContract: contract.address,
        },
        {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        },
        {
          owner: signer._address,
          spender: addressToApprove,
          value,
          nonce,
          deadline,
        },
      );

      const splitSignature = utils.splitSignature(signature);

      return {
        ...splitSignature,
        deadline,
      };
    }

    throw new Error('Unable to get signer');
  }
}
