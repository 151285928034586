import { render, staticRenderFns } from "./DiscordConnect.vue?vue&type=template&id=ef6edd30&"
import script from "./DiscordConnect.vue?vue&type=script&lang=ts&"
export * from "./DiscordConnect.vue?vue&type=script&lang=ts&"
import style0 from "./DiscordConnect.vue?vue&type=style&index=0&id=ef6edd30&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DiscordDialog: require('/builds/gala-games/gala-film/gala-film/src/components/Discord/DiscordDialog.vue').default,DiscordConnectingDialog: require('/builds/gala-games/gala-film/gala-film/src/components/Discord/DiscordConnectingDialog.vue').default})
