import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { ExternalProvider } from '@ethersproject/providers';
import { Network } from '~/store/web3_wallet/types';

const APP_NAME = 'Gala Film';
const APP_LOGO_URL = 'https://film.gala.com/favicon-96x96.png';
const DEFAULT_CHAIN_ID =
  process.env.networkType === 'testnet' ? Network.sepolia : Network.mainnet;
const JSON_RPC_URL = process.env.ethNodeUrl;

// Coinbase Wallet Provider
export const getCoinbaseWalletProvider = () => {
  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: APP_NAME,
    appLogoUrl: APP_LOGO_URL,
    darkMode: true,
    overrideIsMetaMask: false,
  });
  return coinbaseWallet.makeWeb3Provider(JSON_RPC_URL, DEFAULT_CHAIN_ID);
};

// MetaMask Provider
export const getMetaMaskProvider = () => {
  const windowEthereum = window.ethereum as ExternalProvider;
  let provider = windowEthereum?.isMetaMask ? windowEthereum : undefined;
  // @ts-ignore - edge case if MM and CBW are both installed
  const ethereumProviders = window.ethereum?.providers as ExternalProvider[];
  if (ethereumProviders?.length) {
    provider = ethereumProviders.find(
      // @ts-ignore - edge case if MM and CBW are both installed
      (p) => p.isMetaMask && !p.overrideIsMetaMask,
    );
  }
  return provider;
};

// OKX Provider
export const getOkxProvider = () => {
  const windowOkx = (
    window as unknown as Window & {
      okxwallet?: ExternalProvider & { isOkxWallet: boolean };
    }
  ).okxwallet;

  return windowOkx?.isOkxWallet ? windowOkx : undefined;
};
